import axios from "axios";
import { baseUrl, headers } from "../config/env";
headers
export function postNice(accountId){
    return axios.post(baseUrl+"/api/v1/nice",accountId);
}

export function getNice(sEncodeData){
    return axios.get(baseUrl+"/api/v1/nice" ,{
        params : {
            sEncodeData: sEncodeData
        }
    })
}